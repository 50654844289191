<template>
  <base-page>
    <router-view />
  </base-page>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TaskPage',

  meta () {
    return {
      titleTemplate: title => `${title} - Tâches | AGORA`
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
  },

  beforeDestroy () {
    this.$store.commit('tasks/setCurrentTask', null)
  },

  computed: {
    ...mapGetters({
      task: 'tasks/getCurrentTask'
    })
  },

  watch: {
    task: {
      handler (newVal, oldVal) {
        if (!newVal) return
        this.$store.commit('pages/setPageTitle', newVal['label'])

        const id = this.$route.params.id
        const { commentaires_count: comments } = newVal

        this.$store.commit('pages/setTabs', id ? [
          { name: 'informations', label: 'Informations', to: { name: 'task-show', params: { id } } },
          { name: 'comments', label: `${comments} ${this.$pluralize(this.$t('comments.comments'), comments)}`, to: { name: 'task-comments', params: { id } } }
        ] : [])
      },
      immediate: true
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('tasks.tasks_list'), to: { name: 'tasks-list' } },
      {}
    ])

    if (!this.$route.params.id) {
      this.$store.commit('pages/setPageTitle', this.$t('tasks.create_task'))
    }
  }
}
</script>
